import React from 'react';
import { ISpacerProps } from './types';
import styled from 'styled-components';

const StyledSpacer = styled.div<{ size: number; background?: string }>`
  background: ${({ background }) => background};
  height: ${({ size }) => size}px;
`;

export const Spacer = (props: ISpacerProps): JSX.Element => {
  return <StyledSpacer {...props} />;
};

Spacer.defaultProps = {
  size: 60,
};
